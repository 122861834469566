import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import gmimg from "../assets/img/Digital-marketing.jpg"


const data = [
    {
      type: 'SEO Optimization',
      functionalities: 'Keyword research, on-page SEO, technical SEO, backlinking',
      example: 'Ahrefs, SEMrush',
      costEstimate: '$2,000 to $10,000+',
    },
    {
      type: 'Social Media Marketing',
      functionalities: 'Content creation, campaign management, analytics',
      example: 'Facebook Ads, Instagram Ads',
      costEstimate: '$3,000 to $15,000+',
    },
    {
      type: 'Content Marketing',
      functionalities: 'Blog posts, infographics, video content, eBooks',
      example: 'HubSpot, Contentful',
      costEstimate: '$1,000 to $8,000+',
    },
    {
      type: 'Email Marketing',
      functionalities: 'Campaign automation, A/B testing, analytics',
      example: 'Mailchimp, Constant Contact',
      costEstimate: '$500 to $5,000+',
    },
    {
      type: 'Pay-Per-Click (PPC) Advertising',
      functionalities: 'Campaign strategy, bid management, conversion tracking',
      example: 'Google Ads, Bing Ads',
      costEstimate: '$2,000 to $20,000+',
    },
];

const DigitalMarketingDevelopmentPage = () => {
  return (
    <section className="py-16 px-10 bg-gray-100">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">Digital Marketing Services</h1>

        <div className="flex flex-col md:flex-row">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-4 mb-8">
            <h2 className="text-2xl font-semibold mb-4">About Digital Marketing</h2>
            <p className="text-gray-700 mb-4">
              Our digital marketing services are tailored to boost your brand’s visibility and engagement across platforms.
              From SEO to social media and email campaigns, we use a data-driven approach to achieve tangible results.
            </p>
            <h3 className="text-lg font-semibold mb-2">Services We Offer:</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                SEO Optimization
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Social Media Marketing
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Content Marketing
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Email Marketing
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                PPC Advertising
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-4 mb-8">
            <img src={gmimg} alt="Digital Marketing Services" className="rounded-lg shadow-lg" />
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
        <div className="overflow-x-auto px-20 pt-5">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="border border-gray-300 px-4 py-2">Service Type</th>
                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                <th className="border border-gray-300 px-4 py-2">Example Tools</th>
                <th className="border border-gray-300 px-4 py-2">Cost Estimate</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.costEstimate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Technology We Use</h2>
        <p className="text-gray-700 text-center mb-4">
          We leverage industry-leading tools and technologies to optimize your digital marketing campaigns.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-chart-line text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Google Analytics</h3>
            <p className="text-gray-600">Gain insights into user behavior and campaign performance.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fab fa-facebook-square text-blue-700 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Facebook Ads</h3>
            <p className="text-gray-600">Reach targeted audiences with precision.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-envelope-open-text text-yellow-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Mailchimp</h3>
            <p className="text-gray-600">Email campaigns and marketing automation.</p>
          </div>
        </div>

       
      </div>
    </section>
  );
};

export default DigitalMarketingDevelopmentPage;
