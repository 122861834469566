import React from 'react'; // Make sure to import your image
import { FaCheckCircle } from 'react-icons/fa'; // Import approved symbol icon
import gd from "../assets/img/graphics.jpeg"


const data = [
  {
    type: 'Logo Design',
    functionalities: 'Custom logo creation, brand identity, color palette selection',
    example: 'Nike, Apple',
    costEstimate: '$500 to $5,000',
  },
  {
    type: 'Website Graphics',
    functionalities: 'UI/UX design, custom graphics, web animations',
    example: 'Shopify, Dropbox',
    costEstimate: '$1,000 to $10,000+',
  },
  {
    type: 'Social Media Graphics',
    functionalities: 'Content templates, post design, banner creation',
    example: 'Instagram, Facebook ads',
    costEstimate: '$300 to $3,000+',
  },
  {
    type: 'Infographics',
    functionalities: 'Data visualization, custom icons, storytelling visuals',
    example: 'Company reports, educational content',
    costEstimate: '$500 to $4,000+',
  },
  {
    type: 'Print Design',
    functionalities: 'Brochures, posters, flyers, business cards',
    example: 'Corporate brochures, event posters',
    costEstimate: '$300 to $5,000+',
  },
  {
    type: 'Brand Guidelines',
    functionalities: 'Consistent brand visuals, typography, logo usage',
    example: 'Coca-Cola brand book',
    costEstimate: '$1,000 to $10,000+',
  },
  {
    type: 'Packaging Design',
    functionalities: 'Product packaging, label design, brand consistency',
    example: 'Apple product boxes, luxury brand packaging',
    costEstimate: '$1,500 to $10,000+',
  },
  {
    type: 'Illustration',
    functionalities: 'Custom illustrations, vector art, unique graphics',
    example: 'Children’s books, company mascots',
    costEstimate: '$500 to $5,000+',
  },
];

const GraphicDevelopmentPage = () => {
  return (
    <section className="py-16 px-10 bg-gray-100">
      <div className="container mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">Graphic Design Services</h1>
        
        <div className="flex flex-col md:flex-row">
          {/* Left Column */}
          <div className="md:w-1/2 md:pr-4 mb-8">
            <h2 className="text-2xl font-semibold mb-4">About Our Graphic Design</h2>
            <p className="text-gray-700 mb-4">
              We bring your brand's vision to life through visually compelling design. Our team crafts custom graphics that resonate with your audience, creating lasting impressions.
            </p>
            <h3 className="text-lg font-semibold mb-2">Services We Offer:</h3>
            <ul className="list-disc list-inside mb-4">
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Logo & Brand Identity Design
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Social Media & Web Graphics
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Infographics & Data Visualization
              </li>
              <li className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                Print & Packaging Design
              </li>
            </ul>
          </div>

          {/* Right Column */}
          <div className="md:w-1/2 md:pl-4 mb-8">
            <img src={gd} alt="Graphic Design Services" className="rounded-lg shadow-lg" />
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
        <div className="overflow-x-auto px-20 pt-5">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200 text-gray-600">
                <th className="border border-gray-300 px-4 py-2">Service Type</th>
                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                <th className="border border-gray-300 px-4 py-2">Examples</th>
                <th className="border border-gray-300 px-4 py-2">Cost Estimate</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                  <td className="border border-gray-300 px-4 py-2">{item.costEstimate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Technologies & Tools</h2>
        <p className="text-gray-700 text-center mb-4">
          We use the best tools in the industry to ensure your graphics are top-quality.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-pen-nib text-purple-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Adobe Illustrator</h3>
            <p className="text-gray-600">Perfect for vector graphics and logo design.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-layer-group text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Adobe Photoshop</h3>
            <p className="text-gray-600">Ideal for photo editing and web graphics.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fab fa-sketch text-yellow-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Sketch</h3>
            <p className="text-gray-600">UI/UX design tool for creating website layouts.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-drafting-compass text-indigo-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">InDesign</h3>
            <p className="text-gray-600">Best for brochure and magazine layouts.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-object-group text-green-600 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">CorelDRAW</h3>
            <p className="text-gray-600">Great for vector illustrations and layouts.</p>
          </div>
          <div className="bg-white shadow-lg p-6 rounded-lg text-center">
            <i className="fas fa-network-wired text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-xl font-semibold mb-2">Figma</h3>
            <p className="text-gray-600">Collaborative tool for UI/UX and design prototyping.</p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center my-8">Our Creative Process</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-700 text-center mb-8 max-w-5xl mx-auto">
          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-lightbulb text-yellow-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">01. Ideation & Concept</h3>
            <p>Collaborate to brainstorm and refine ideas for impactful designs.</p>
          </div>           <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-pencil-alt text-blue-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">02. Design & Prototyping</h3>
            <p>Create and iterate on initial designs and prototypes for client feedback.</p>
          </div>

          <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
            <i className="fas fa-check-circle text-green-500 text-5xl mb-4"></i>
            <h3 className="text-lg font-semibold mb-2">03. Review & Finalization</h3>
            <p>Gather feedback and finalize designs for production and delivery.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GraphicDevelopmentPage;
 


