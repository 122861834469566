import React from 'react';

const Career = () => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-gray-100 py-12">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-semibold text-center mb-12 text-black-800">Join Our Internship Program</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Internship Opening: MERN Stack Developer */}
          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">MERN Stack Developer</h3>
            <p className="mt-4">
              We're seeking a MERN Stack Developer intern to work on full-stack projects using MongoDB, Express, React, and Node.js.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in MERN stack development</p>
            <p className="mt-2"><strong>Skills Required:</strong> MongoDB, Express, React.js, Node.js</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/W6cG14ivXFjDymBx9" 
              target="_blank" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>
          {/* Internship Opening: React Native Developer */}
          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">Mobile App Developer (Android, iOS Developer)</h3>
            <p className="mt-4">
              We are looking for a passionate React Native Developer intern to help us build high-quality mobile applications.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in React Native development</p>
            <p className="mt-2"><strong>Skills Required:</strong> React Native, JavaScript, Git, REST APIs</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/h2PcnXUAgCoEsakK9" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>

          {/* Internship Opening: UI/UX Designer */}
          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">UI/UX Designer</h3>
            <p className="mt-4">
              We are looking for a creative UI/UX Designer intern to join our team and design user-friendly interfaces for our applications.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in UI/UX design</p>
            <p className="mt-2"><strong>Skills Required:</strong> Adobe XD, Figma, Sketch, Wireframing, Prototyping, UX Research</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/azde31Caxk4gUyFWA" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>

          {/* Internship Opening: Digital Marketing Specialist */}
          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">Digital Marketing Specialist</h3>
            <p className="mt-4">
              Join our internship as a Digital Marketing Specialist to grow our online presence and enhance brand awareness.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in digital marketing</p>
            <p className="mt-2"><strong>Skills Required:</strong> SEO, SEM, Social Media, Google Analytics, Email Marketing</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/CY6WeoQEYUEG8YpS8" 
              target="_blank" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>

          {/* Internship Opening: AWS and DevOps Specialist */}
          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">AWS and DevOps Specialist</h3>
            <p className="mt-4">
              We're looking for a dedicated AWS and DevOps Specialist intern to assist in managing cloud infrastructure and implementing automation solutions.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in AWS and DevOps</p>
            <p className="mt-2"><strong>Skills Required:</strong> AWS Services, CI/CD, Docker, Kubernetes, Terraform, Linux</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/prwzLXgkNAZKhiDF9" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>

          <div className="bg-white p-8 shadow-lg rounded-lg text-gray-800 transform hover:scale-105 transition-transform duration-300">
            <h3 className="text-2xl font-bold">Sales Executive</h3>
            <p className="mt-4">
              We're hiring an intern Sales Executive to assist in business development and client engagement.
            </p>
            <p className="mt-2"><strong>Location:</strong> Work from Home</p>
            <p className="mt-2"><strong>Experience:</strong> 0-2 years in sales or business development</p>
            <p className="mt-2"><strong>Skills Required:</strong> Sales, Communication, CRM Tools</p>
            <p className="mt-2"><strong>Start Date:</strong> 25-10-2024</p>
            <p className="mt-2"><strong>End Date:</strong> 15-11-2024</p>
            <a 
              href="https://forms.gle/RscPonWYuae1JPzs5" 
              target="_blank" 
              className="inline-block mt-6 px-6 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Apply Now
            </a>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Career;
