import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-sky-700 text-white pt-12 font-sans">
      <div className="container mx-auto px-5 pb-10 grid grid-cols-1 md:grid-cols-4 gap-10 text-left">
        
        {/* Footer Info */}
        <div className="space-y-4">
          <h3 className="text-2xl font-semibold">YSL Web</h3>
          <p className="text-slate-100 leading-relaxed">
            As part of the Yahshua Group, our goal is to bring every business online, offering cutting-edge digital solutions that elevate your brand. We believe in delivering high-quality projects at competitive prices, ensuring customer satisfaction and lasting partnerships.
          </p>
        </div>

        {/* About Us Links */}
        <div className="space-y-4">
          <h4 className="text-xl font-semibold ml-20">About Us</h4>
          <ul className="space-y-2 text-white ml-20">
            <li><a href="#" className="text-slate-100 hover:text-white">Home</a></li>
            <li><a href="/about" className="text-slate-100 hover:text-white">About Us</a></li>
            <li><a href="/services" className="text-slate-100 hover:text-white">Our Services</a></li>
            <li><a href="/contact" className="text-slate-100 hover:text-white">Terms & Conditions</a></li>
            <li><a href="/career" className="text-slate-100 hover:text-white">Career</a></li>
            <li><a href="#" className="text-slate-100 hover:text-white">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Contact Us */}
        <div className="space-y-4">
          <h4 className="text-xl font-semibold">Contact Us</h4>
          <address className="text-slate-100 leading-relaxed">
            Location: Pune, India<br />
            <strong className="text-white">Email : </strong> 
            <a href="mailto:yahshuasoftware@gmail.com" className="text-slate-100 hover:text-white">
              yahshuasoftware@gmail.com
            </a><br />
            <strong className="text-white">Phone : </strong> 
            <a href="tel:+91234567890" className="text-slate-100 hover:text-white">
            +91 8850115960
            </a>
          </address>
        </div>

        {/* Subscription */}
        <div className="space-y-4">
          <h4 className="text-xl font-semibold">Subscription</h4>
          <form className="flex flex-col space-y-2">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              className="w-full p-3 border border-gray-600 rounded bg-gray-100 text-gray-900 placeholder-gray-900"
              aria-label="Enter your email to subscribe"
            />
            <input
              type="submit"
              value="Subscribe"
              className="w-full bg-blue-500 text-white p-3 rounded cursor-pointer hover:bg-blue-900"
              aria-label="Subscribe to our newsletter"
            />
          </form>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="bg-gray-100 py-4">
        <div className="container mx-auto px-4 text-center text-gray-900">
          &copy; {new Date().getFullYear()} Yahshua Software Ltd. All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
