import React from 'react';
import { FaArrowRight } from 'react-icons/fa'; // Import an icon from react-icons

const CallToAction = () => {
  return (
    <section
      className="bg-cover bg-center bg-no-repeat py-16 sm:py-20"
      style={{ backgroundImage: `url('banner3.jpg')` }} // Add your image path here
      aria-label="Promotional background for call to action section"
    >
      <div className="container mx-auto text-center text-white px-4 sm:px-6 md:px-8">
        <header className="mb-8 sm:px-10 md:px-16 lg:px-28">
          <h3 className="text-2xl sm:text-3xl font-semibold mb-4">
            Call to Action
          </h3>
          <p className="text-base sm:text-lg">
            Partner with Yahshua Software Ltd for innovative, tailored digital solutions. Contact us today to start your journey toward online success!
          </p>
        </header>
        <a 
          className="inline-block mt-8 px-6 py-3 bg-blue-500 text-white text-sm sm:text-lg font-semibold rounded shadow-lg hover:bg-blue-900 transition-colors duration-300"
          href="https://wa.me/918850115960?text=Hello%2C%20I%27m%20interested%20in%20your%20services%20and%20would%20like%20to%20know%20more."
          target="_blank"
          rel="noopener noreferrer"
          role="button"
          aria-label="Contact us on WhatsApp"
        >
          Call To Action <FaArrowRight className="inline ml-2"/>
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
