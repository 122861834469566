import React from 'react'; // Make sure to import your image
import { FaCheckCircle } from 'react-icons/fa'; // Import approved symbol icon
import gameimg from "../assets/img/Game-Development.webp"
import { FaUnity } from 'react-icons/fa';
import { FaGamepad } from 'react-icons/fa'; // Using a gamepad icon as a substitute
import { FaCode } from 'react-icons/fa';

const data = [
    {
        type: 'Mobile Game',
        functionalities: 'Touch controls, optimized for performance, multiplayer modes',
        example: 'Candy Crush, Clash of Clans',
        costEstimate: '$20,000 to $150,000+',
    },
    {
        type: 'PC Game',
        functionalities: 'Advanced graphics, keyboard/mouse controls, various genres',
        example: 'Minecraft, Fortnite',
        costEstimate: '$50,000 to $300,000+',
    },
    {
        type: 'Console Game',
        functionalities: 'High-quality graphics, support for game controllers, multiplayer options',
        example: 'Call of Duty, FIFA',
        costEstimate: '$100,000 to $500,000+',
    },
    {
        type: 'VR Game',
        functionalities: 'Immersive VR experiences, motion controls, optimized for VR platforms',
        example: 'Beat Saber, Half-Life: Alyx',
        costEstimate: '$70,000 to $400,000+',
    },
    {
        type: 'AR Game',
        functionalities: 'Augmented reality, GPS integration, real-world interactions',
        example: 'Pokémon Go, Harry Potter: Wizards Unite',
        costEstimate: '$30,000 to $150,000+',
    },
];

const GameDevelopmentPage = () => {
    return (
        <section className="py-16 px-10 bg-gray-100">
            <div className="container mx-auto">
                <h1 className="text-4xl font-bold text-gray-800 text-center mb-12">Game Development</h1>
                
                <div className="flex flex-col md:flex-row">
                    {/* Left Column */}
                    <div className="md:w-1/2 md:pr-4 mb-8">
                        <h2 className="text-2xl font-semibold mb-4">About Game Development</h2>
                        <p className="text-gray-700 mb-4">
                            Our game development services are designed to bring your creative ideas to life.
                            From concept design to final deployment, we build engaging games with stunning graphics, 
                            smooth gameplay, and immersive storytelling for various platforms.
                        </p>
                        <h3 className="text-lg font-semibold mb-2">Services We Offer:</h3>
                        <ul className="list-disc list-inside mb-4">
                            <li className="flex items-center mb-2">
                                <FaCheckCircle className="text-green-500 mr-2" />
                                Concept and Story Development
                            </li>
                            <li className="flex items-center mb-2">
                                <FaCheckCircle className="text-green-500 mr-2" />
                                Character and Level Design
                            </li>
                            <li className="flex items-center mb-2">
                                <FaCheckCircle className="text-green-500 mr-2" />
                                Multiplayer and Social Integration
                            </li>
                            <li className="flex items-center mb-2">
                                <FaCheckCircle className="text-green-500 mr-2" />
                                Testing and Quality Assurance
                            </li>
                        </ul>
                    </div>

                    {/* Right Column */}
                    <div className="md:w-1/2 md:pl-4 mb-8">
                        <img src={gameimg} alt="Game Development Services" className="rounded-lg shadow-lg" />
                    </div>
                </div>

                <h2 className="text-2xl font-semibold text-center my-8 underline">Our Pricing</h2>
                <div className="overflow-x-auto px-20 pt-5">
                    <table className="min-w-full border-collapse border border-gray-300">
                        <thead>
                            <tr className="bg-gray-200 text-gray-600">
                                <th className="border border-gray-300 px-4 py-2">Type</th>
                                <th className="border border-gray-300 px-4 py-2">Functionalities</th>
                                <th className="border border-gray-300 px-4 py-2">Example</th>
                                <th className="border border-gray-300 px-4 py-2">Cost Estimate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-100">
                                    <td className="border border-gray-300 px-4 py-2">{item.type}</td>
                                    <td className="border border-gray-300 px-4 py-2">{item.functionalities}</td>
                                    <td className="border border-gray-300 px-4 py-2">{item.example}</td>
                                    <td className="border border-gray-300 px-4 py-2">{item.costEstimate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h2 className="text-2xl font-semibold text-center my-8">Technologies We Use</h2>
                <p className="text-gray-700 text-center mb-4">
                    We utilize top game development tools and technologies to deliver immersive gaming experiences.
                </p>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 my-8 mx-auto max-w-4xl">
      <div className="bg-white shadow-lg p-6 rounded-lg text-center flex flex-col items-center">
        <FaUnity className="text-black text-5xl mb-4" />
        <h3 className="text-xl font-semibold mb-2">Unity</h3>
        <p className="text-gray-600">
          Cross-platform game engine used for 2D and 3D game development.
        </p>
      </div>
      <div className="bg-white shadow-lg p-6 rounded-lg text-center flex flex-col items-center">
        <FaGamepad className="text-blue-700 text-5xl mb-4" />
        <h3 className="text-xl font-semibold mb-2">Unreal Engine</h3>
        <p className="text-gray-600">
          High-end game engine known for photorealistic graphics.
        </p>
      </div>
      <div className="bg-white shadow-lg p-6 rounded-lg text-center flex flex-col items-center">
        <FaCode className="text-green-500 text-5xl mb-4" />
        <h3 className="text-xl font-semibold mb-2">C# & C++</h3>
        <p className="text-gray-600">
          Core programming languages for game development in Unity and Unreal.
        </p>
      </div>
    </div>

                <h2 className="text-2xl font-semibold text-center my-8">Understand the Development Process</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-gray-700 text-center mb-8 max-w-5xl mx-auto">
                    <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
                        <i className="fas fa-pencil-ruler text-blue-500 text-5xl mb-4"></i>
                        <h3 className="text-lg font-semibold mb-2">01. Concept & Planning</h3>
                        <p>We collaborate with you to shape ideas into an actionable plan.</p>
                    </div>
                    <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
                        <i className="fas fa-gamepad text-green-500 text-5xl mb-4"></i>
                        <h3 className="text-lg font-semibold mb-2">02. Development & Testing</h3>
                        <p>Development, followed by rigorous testing to ensure a smooth experience.</p>
                    </div>
                    <div className="bg-white shadow-lg p-6 rounded-lg transform transition-all duration-500 hover:scale-105 hover:shadow-2xl">
                        <i className="fas fa-rocket text-red-500 text-5xl mb-4"></i>
                        <h3 className="text-lg font-semibold mb-2">03. Launch & Support</h3>
                        <p>Launch on desired platforms with post-launch updates and maintenance.</p>
                    </div>
                </div>

               
            </div>
        </section>
    );
};

export default GameDevelopmentPage;
