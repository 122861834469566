import React from 'react';
import { FaRegLightbulb, FaEye, FaCogs } from 'react-icons/fa';

const aboutData = [
  {
    imgSrc: 'about-mission1.jpg',
    icon: <FaRegLightbulb className="text-blue-500" />,
    title: 'Our Mission',
    description: 'To transform all Yahshua Group businesses into thriving online platforms. We aim to deliver high-quality development services to clients at the best price and with exceptional value.',
    link: '#',
  },
  {
    imgSrc: 'about-vision1.jpg',
    icon: <FaEye className="text-blue-500" />,
    title: 'Our Vision',
    description: 'To become a leading force in digital transformation, empowering businesses to succeed online. We envision a future where Yahshua Software Ltd bridges the gap between innovation and accessibility for all.',
    link: '#',
  },
  {
    imgSrc: 'about-objective1.jpg',
    icon: <FaCogs className="text-blue-500" />,
    title: 'Our Objective',
    description: 'To provide innovative, tailor-made solutions for web, mobile, and game development. We strive to meet market demands while maintaining excellence and efficiency in every project.',
    link: '#',
  },
];

const About = () => {
  return (
    <section
      className="py-12 px-4 sm:px-10 bg-gray-50 bg-gradient-to-b from-white to-blue-100"
      aria-labelledby="about-heading"
    >
      <div className="container mx-auto">
        {/* Header */}
        <header className="text-center mb-12 px-4 sm:px-28">
          <h3
            id="about-heading"
            className="text-2xl sm:text-3xl font-bold mb-4 text-gray-800"
          >
            About Us
          </h3>
          <p className="text-base sm:text-lg text-gray-700">
            Empowering Businesses with Innovative Digital Solutions. Transforming Ideas into Reality with Expertise in Development. We deliver innovative solutions to help you thrive in the digital world.
          </p>
        </header>

        {/* Welcome Section */}
        <div className="flex flex-wrap items-center mb-12">
          <div className="w-full sm:w-1/2 mb-8 sm:mb-0">
            <img
              src="about1.png"
              alt="About Yahshua Software Ltd - Bringing Businesses Online"
              className="w-full h-auto"
            />
          </div>
          <div className="w-full sm:w-1/2 pl-0 sm:pl-8">
            <h2 className="text-xl sm:text-2xl font-bold mb-4">
              <a  className="text-[#0f3c94] hover:underline">
                Welcome to Our Site
              </a>
            </h2>
            <p className="text-base sm:text-lg text-gray-700 mb-6">
              Welcome to Yahshua Software Ltd, where innovation meets excellence. As part of the Yahshua Group, our goal is to bring every business online, offering cutting-edge digital solutions that elevate your brand. We believe in delivering high-quality projects at competitive prices, ensuring customer satisfaction and lasting partnerships.
            </p>
          </div>
        </div>

        {/* Cards Section */}
        <div className="flex flex-wrap -mx-4">
          {aboutData.map((item, index) => (
            <article
              key={index}
              className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8"
              aria-labelledby={`about-item-${index}`}
            >
              <div className="bg-white rounded-lg shadow-lg p-6 text-center relative flex flex-col min-h-[300px]">
                <div className="relative mb-4">
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
                    <div className="p-4 rounded-full">
                      <div className="text-4xl pt-4">{item.icon}</div>
                    </div>
                  </div>
                </div>
                <h3
                  id={`about-item-${index}`}
                  className="text-lg sm:text-xl font-bold mb-2 mt-6 text-[#0f3c94]"
                >
                  {item.title}
                </h3>
                <p className="text-sm sm:text-base text-gray-700 mb-4 flex-grow">
                  {item.description}
                </p>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
