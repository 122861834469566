import React from 'react';
import '../index.css';
// Import the necessary icons from React Icons
import { FaWhatsapp, FaPhone, FaEnvelope, FaCalendarAlt } from 'react-icons/fa';
import { FaMapMarkerAlt, FaMobileAlt } from 'react-icons/fa';
import { FiInstagram, FiFacebook, FiLinkedin, FiTwitter } from 'react-icons/fi';

const ContactUs = () => {
  return (
    <section className="contact py-16 px-4 md:px-10 w-full bg-gray-50 flex flex-col items-center justify-center">
      <div className="container mx-auto px-4">
        <div className="section-header text-center mb-12">
          <h3 className="text-3xl font-bold mb-4">Contact Us</h3>
          <p className="text-lg text-gray-600">
            Have questions or ready to start a project? Reach out to our team for personalized support and solutions tailored to your needs.
          </p>
        </div>
        <div className="container mx-auto flex flex-col lg:flex-row justify-between px-4 mt-12">
          {/* Left Section */}
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-4xl font-bold">Get in touch with our Expert Team</h2>
            <p className="mt-4">
              Have something in mind? Need some advice?<br />
              We’d love to be a pilot of your digital journey.<br />
              We are reachable at many platforms. You can directly contact us at:
            </p>
          </div>

          {/* Right Section */}
          <div className="lg:w-1/2">
  <ul className="space-y-4">
    <li className="flex items-center">
      <FaMapMarkerAlt className="mr-2" />
      <span>Hinjewadi phase 1, pune, 411057 </span>
    </li>
    <li className="flex items-center">
      <FaEnvelope className="mr-2" />
      <span>yahshuasoftware@gmail.com</span>
    </li>
   <li className="flex items-center">
      <FaMobileAlt className="mr-2" />
      <span>+91 88501 15960</span>
    </li>
      {/*<li className="flex items-center">
      <FaPhone className="mr-2" />
      <span>+91 8850115960</span>
    </li> */}
  </ul>
</div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
